import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Select } from 'antd';
import { globalHistory } from '@reach/router';
import scrollTo from 'gatsby-plugin-smoothscroll';
import style from './blog_template.module.scss';
import { Layouts, Button } from '../components/components';
import { StrapiArticlesBlogCategory, StrapiArticles } from '../graphql-types';
import { pathTo } from '../lib/utils';
import Article from '../modules/SharedModules/LatestArticles/Articles/Articles';
import { FontWeight } from '../lib/constants/enums';

interface BlogTemplate {
  slug: string;
  lang: string;
  categoryArticles: StrapiArticles[] | undefined;
  blogCategories: StrapiArticlesBlogCategory[] | undefined;
  article: StrapiArticles | undefined;
  allArticles: StrapiArticles[];
}

interface BlogTemplateContext {
  pageContext: BlogTemplate;
}

const BlogTemplate: React.FC<BlogTemplateContext> = ({ pageContext }) => {
  const { article, blogCategories, slug, allArticles = [] } = pageContext;
  const { IndexLayout, Container, AdaptiveLayout, LayoutBG } = Layouts;
  const { Option } = Select;
  const sortNewestArticles = allArticles?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).slice(0, 2);
  const [render, setRender] = useState(false);

  const [h2, setH2] = useState<any>([]);
  const [filterArticlesByCategory, setFilterArticlesByCategory] = useState<any>([]);

  useEffect(() => {
    if (!article) return;
    setH2([...document.querySelectorAll('h2')]);
    const articlesByCategory = allArticles.filter(
      el => el.blog_category?.routeName === article.blog_category?.routeName && el.id !== article.id,
    );
    setFilterArticlesByCategory(articlesByCategory);
  }, [article, render]);

  const createTOC = (arr: string[]) => {
    arr.forEach((item: string) => {
      // eslint-disable-next-line no-unused-expressions
      h2.find(el => el.innerText === item)?.setAttribute('id', item.replaceAll(' ', '-').replaceAll(':', '-'));
    });

    return arr
      .filter(item => item)
      .map((item: string, index: number) => (
        <li key={`${index}TOC`}>
          <a onClick={() => scrollTo(`#${item.replaceAll(' ', '-').replaceAll(':', '-')}`)}>{item}</a>
        </li>
      ));
  };

  const backLink = () => {
    const str = globalHistory.location.pathname;

    return str.slice(0, str.lastIndexOf('/'));
  };

  // pls dont remove, without it after rerender page breaks
  useEffect(() => setRender(true), []);

  return (
    render && (
      <IndexLayout article={article}>
        <AdaptiveLayout onDesktop onTablet>
          <LayoutBG
            className={style.blogTemplatePromo}
            background={`url(${process.env.STRAPI_API_URL}/uploads/blog_0a7a2c6520.png) bottom right / cover no-repeat`}
          >
            <Container className={style.blogContainer}>
              <Link to={article ? pathTo(backLink()) : pathTo('blog')} className={style.backLink}>
                Вернуться назад
              </Link>
              <h2 className={style.promoTitle}>Правильное питание</h2>
              <h5 className={style.promoInfo}>Приятного аппетита и прочтения</h5>
            </Container>
          </LayoutBG>
        </AdaptiveLayout>
        <AdaptiveLayout onMobile>
          <LayoutBG
            className={style.blogTemplatePromo}
            background={`url(${process.env.STRAPI_API_URL}/uploads/blog_mob_b9ac899d51.png) center right / cover no-repeat`}
          >
            <Container className={style.blogContainer}>
              <Link to={article ? pathTo(`blog/${article.blog_categories[0]?.routeName}`) : pathTo('blog')}
                    className={style.backLink}>
                Вернуться назад
              </Link>
              <h2 className={style.promoTitle}>
                ПРАВИЛЬНОЕ <span>ПИТАНИЕ</span>
              </h2>
              <h5 className={style.promoInfo}>Приятного аппетита и прочтения</h5>
            </Container>
          </LayoutBG>
        </AdaptiveLayout>
        <Container className={style.blogTemplateContainer}>
          <div className={style.templateRow}>
            {!article ? (
              <div className={style.artList}>
                {allArticles?.reduce((res, art, index) => {
                  const isCategoryRelated = art.blog_categories.some(
                    item => item.routeName === globalHistory.location.pathname.split('/')[2],
                  );
                  if (isCategoryRelated) {
                    res.push(<Article art={art} slug={slug} key={`${index}ARTICLE`}/>);
                  }
                  return res;
                }, [])}
              </div>
            ) : (
              <div className={style.articlePage}>
                <div className={style.articleHead}>
                  <h1 className={style.articleTitle}>{article.title}</h1>
                  <span className={style.articleDate}>
                  {new Date(article.created_at).toLocaleString('ru', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
                </div>
                <div className={style.articleToc}>
                  {article.TableOfContent && (
                    <>
                      <h3>Оглавление:</h3>
                      <ul>{createTOC(article.TableOfContent.split(', '))}</ul>
                    </>
                  )}
                </div>
                <div className={style.articlePicture}>
                  <img src={`${process.env.STRAPI_API_URL}${article.picture?.url}`} alt=""/>
                </div>
                <div className={style.articleContent}>
                  <ReactMarkdown
                    children={article.content || ''}
                    transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              </div>
            )}
            <div className={style.sidebar}>
              <a
                className={style.sidebarSubscribe}
                href="https://subscribe.goslim.pro/subpage-subscribe_to_our_mailing_list"
                target="_blank"
                rel="noreferrer"
              >
                <Button title="Подписаться" border fontSize={14} fontWeight={FontWeight.bold}/>
              </a>
              <div className={style.navigation}>
                <h4 className={style.navigationTitle}>Навигация по блогу:</h4>
                <AdaptiveLayout onDesktop onTablet>
                  <div className={style.navigationList}>
                    {blogCategories?.map((navItem, index) => (
                      <Link to={pathTo(`blog/${navItem.routeName}`)} className={style.navItem}
                            key={navItem?.id || index}>
                        {navItem.name}
                      </Link>
                    ))}
                  </div>
                </AdaptiveLayout>
                <AdaptiveLayout onMobile>
                  <Select
                    style={{ width: '100%' }}
                    defaultValue={article?.blog_categories[0]?.name || blogCategories?.find(el => el.routeName === slug)?.routeName || slug}
                  >
                    {blogCategories?.map((navItem, index) => (
                      <Option value={navItem.routeName || ''} key={navItem?.id || index}>
                        <Link to={pathTo(`blog/${navItem.routeName}`)} className={style.navItem}>
                          {navItem.name}
                        </Link>
                      </Option>
                    ))}
                  </Select>
                </AdaptiveLayout>
              </div>
              <div className={style.latestArticles}>
                <h4 className={style.navigationTitle}>Новые статьи:</h4>
                <div className={style.latestArticlesList}>
                  {sortNewestArticles.map((el, index) => (
                    <Link
                      to={pathTo(`blog/${el.blog_categories[0]?.routeName}/${el.routeName}`)}
                      className={style.latestArticlesItem}
                      key={el.id}
                    >
                      <span>{new Date(el.published_at).toLocaleString('ru', {
                        day: 'numeric',
                        month: 'numeric',
                      })}</span>
                      <h5>{el.title}</h5>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className={style.articlePageCatalogWrap}>
          <Container className={style.blogTemplateContainer}>
            <h3 className={style.articlePageCatalogTitle}>Так же по этой теме:</h3>
            <div className={style.articlePageCatalog}>
              {filterArticlesByCategory?.map((el, index) => (
                <Article art={el} slug={el.blog_categories[0]?.routeName || slug} key={index}/>
              ))}
            </div>
          </Container>
        </div>
      </IndexLayout>
    )
  );
};

export default BlogTemplate;
